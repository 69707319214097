import { ref } from 'vue'

export default () => {
  let draggedItem = ref(null)
  let draggedIndex = ref(-1)
  let storedList = ref(null)
  let dropIndex = ref(-1)
  let dragDirection = ref(null)
  let dragging = ref(false)

  const load = (list) => {
    storedList.value = list
  }

  const dragStart = (event, index) => {
    // Set data transfer to identify internal drags
    event.dataTransfer.setData('application/x-maatregel', 'internal')
    draggedItem.value = storedList.value[index]
    draggedIndex.value = index
    dragging.value = true

    if (index === storedList.value.length - 1) {
      dragDirection.value = 'up'
    } else {
      dragDirection.value = ''
    }
  }

  const dragEnter = (event, index) => {
    // Prevent handling if not an internal drag
    if (!event.dataTransfer.types.includes('application/x-maatregel')) {
      return
    }

    if (draggedIndex.value === index) {
      return
    }

    // Update direction of dragging
    if (index > draggedIndex.value) {
      dragDirection.value = 'down'
    } else {
      dragDirection.value = 'up'
    }

    // Save the index we're over currently
    dropIndex.value = index

    // Perform the swap
    let temp = storedList.value[draggedIndex.value]
    storedList.value.splice(draggedIndex.value, 1)
    storedList.value.splice(dropIndex.value, 0, temp)
    // update draggedIndex to be the new index
    draggedIndex.value = dropIndex.value
  }

  const dragLeave = () => {
    dropIndex.value = -1
  }

  const dragEnd = () => {
    dropIndex.value = -1
    draggedIndex.value = -1
    dragging.value = false
    dragDirection.value = ''
  }

  const dragOver = (event) => {
    // Only allow internal drags
    if (!event.dataTransfer.types.includes('application/x-maatregel')) {
      event.dataTransfer.dropEffect = 'none'
      return
    }
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  return {
    dragStart,
    dragEnd,
    dragEnter,
    dragLeave,
    dragOver,
    load,
    dropIndex,
    dragDirection,
    dragging,
  }
}
