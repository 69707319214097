import { readonly, ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import { config as configBasic } from '@/use/defaults/surveyBasic'
import { config as configFull } from '@/use/defaults/surveyFull'

export default () => {
  // data
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)
  let address = ref(null)

  // handlers
  const loadHandler = loadingHandler()
  const addForm = formHandler()
  const editForm = formHandler()
  const useApi = apiHandler()

  const fields = ref([
    {
      label: 'Survey ID',
      key: 'id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Description',
      key: 'description',
      type: 'string',
    },
    {
      label: 'name',
      key: 'name',
      type: 'string',
    },
    {
      label: 'address',
      key: 'address',
      type: 'string',
      table: false,
    },
    {
      label: 'zip',
      key: 'zip',
      type: 'string',
      table: false,
    },
    {
      label: 'place',
      key: 'place',
      type: 'string',
      table: false,
    },
    {
      label: 'Basis',
      key: 'basic',
      type: 'toggle',
      default: false,
      table: false,
      can_see: 'basic:write',
    },
    {
      label: 'dateCreated',
      key: 'date_created',
      type: 'date',
      add: false,
      edit: false,
    },
    {
      label: 'config',
      key: 'config',
      type: 'json',
      table: false,
      add: false,
      edit: false,
    },
  ])

  // list
  function getRecords(address_id) {
    loadHandler.setLoadingState('get_records', true)

    if (address_id) {
      useApi.requestV2('get', `v1/addresses/${address_id}/surveys`).then((result) => {
        const data = result.data
        records.value = data
        loadHandler.setLoadingState('get_records', false)
      })
    } else {
      const requestFields = ['id', 'description', 'name', 'address', 'zip', 'place', 'date_created']
      const fieldsParam = '?fields=' + requestFields.join(',')
      useApi.requestV2('get', `v1/surveys${fieldsParam}`).then((result) => {
        const data = result.data
        records.value = data
        loadHandler.setLoadingState('get_records', false)
      })
    }
  }

  // add
  function addRecord() {
    const payload = addForm.data.value

    let config_default = {}

    if (payload.basic === true) {
      config_default = configBasic
    } else {
      config_default = configFull
    }

    if (!Object.keys(payload).includes('config')) {
      payload['config'] = config_default
    }

    // check for empty project
    if (payload.project === '') {
      delete payload.project
    }

    loadHandler.setLoadingState('add_record', true)
    useApi.requestV2('post', `v1/surveys`, payload).then(() => {
      loadHandler.setLoadingState('add_record', false)
      showAdd.value = false
      getRecords(address.value)
    })
  }

  // open edit
  function openEdit(data, address_id = null, projects = null) {
    loadHandler.setLoadingState('get_details', true)

    if (address_id) {
      address.value = address_id
    }

    // Find index of existing project field, if any
    const projectFieldIndex = fields.value.findIndex((field) => field.key === 'project')

    // Remove existing project field if found
    if (projectFieldIndex !== -1) {
      fields.value.splice(projectFieldIndex, 1)
    }

    // Define the position where the project field should be inserted
    let position = projectFieldIndex !== -1 ? projectFieldIndex : 3 // Use existing position or a default

    // Insert the project field with fresh projectsLookup
    if (projects?.value) {
      fields.value.splice(position, 0, {
        label: 'Project',
        key: 'project',
        type: 'select',
        options: projects.value.map((project) => ({ value: project.id, label: project.name })),
        table: false,
      })
    }

    // set disabled state
    const disableFields = ['address', 'zip', 'place']
    fields.value.forEach((field) => {
      const field_key = field.key

      if (disableFields.includes(field_key)) {
        field['disabled'] = true
      }
    })

    useApi.requestV2('get', `v1/surveys/${data.id}`).then((response) => {
      const response_data = response.data
      const formOptions = { type: 'edit', fields: fields.value, data: response_data }
      editForm.create(formOptions)
      showEdit.value = true
      loadHandler.setLoadingState('get_details', false)
    })
  }

  function openAdd(prefill, address_id = null, projects = null) {
    // address
    if (address_id) {
      address.value = address_id
    }

    const disableFields = ['address', 'zip', 'place']

    // set disabled state
    if (prefill !== undefined) {
      fields.value.forEach((field) => {
        const field_key = field.key

        if (field_key in prefill && disableFields.includes(field_key)) {
          field['disabled'] = true
        }
      })
    }

    // Find index of existing project field, if any
    const projectFieldIndex = fields.value.findIndex((field) => field.key === 'project')

    // Remove existing project field if found
    if (projectFieldIndex !== -1) {
      fields.value.splice(projectFieldIndex, 1)
    }

    // Define the position where the project field should be inserted
    let position = projectFieldIndex !== -1 ? projectFieldIndex : 3 // Use existing position or a default

    // Insert the project field with fresh projectsLookup
    if (projects?.value) {
      fields.value.splice(position, 0, {
        label: 'Project',
        key: 'project',
        type: 'select',
        options: projects.value.map((project) => ({ value: project.id, label: project.name })),
        table: false,
      })
    }

    const formOptions = { type: 'add', fields: fields.value, data: {} }

    if (prefill) {
      formOptions.data = prefill
    }

    addForm.create(formOptions)
    showAdd.value = true
  }

  function closeEdit() {
    editForm.data.value = {}
    showEdit.value = false
    address.value = null
  }

  function closeAdd() {
    showAdd.value = false
    address.value = null
    addForm.close()
  }

  // edit
  function editRecord() {
    const payload = editForm.data.value

    loadHandler.setLoadingState('edit_record', true)

    useApi.requestV2('patch', `v1/surveys/${payload.id}`, payload).then(() => {
      loadHandler.setLoadingState('edit_record', false)
      getRecords(address.value)
      closeEdit()
    })
  }

  function deleteRecord(value, index, address_id = null) {
    if (address_id) {
      address.value = address_id
    }

    loadHandler.setLoadingState('delete_record', true)
    useApi.requestV2('delete', `v1/surveys/${value}`).then(() => {
      loadHandler.setLoadingState('delete_record', false)
      getRecords(address.value)
    })
  }

  function downloadObjectAsJson(data, fileName) {
    const jsonData = JSON.stringify(data)
    const blob = new Blob([jsonData], { type: 'application/json' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function exportRecord(record) {
    loadHandler.setLoadingState('get_details', true)
    const id = record.id
    const payload = {
      id: id,
    }

    useApi.requestV2('patch', `v1/surveys/${payload.id}`, payload).then((response) => {
      const data = response.data
      const description = data.description

      // filter reserved keys
      const excludeKeys = ['address_id', 'project', 'maatregelen_categorieen', 'id']
      const filteredData = Object.fromEntries(Object.entries(data).filter(([key]) => !excludeKeys.includes(key)))

      // eslint-disable-next-line no-control-regex
      const sanitized_filename = description.replace(/[<>:"\\/|?*\x00-\x1F]/g, '_')
      downloadObjectAsJson(filteredData, sanitized_filename)

      loadHandler.setLoadingState('get_details', false)
    })
  }

  function duplicateRecord(id, address_id = null) {
    loadHandler.setLoadingState('get_details', true)
    const formOptions = { type: 'add', fields: fields.value, data: {} }
    const payload = {
      id: id,
    }

    // Store addres id so it can be used in getRecords
    if (address_id) {
      address.value = address_id
    }

    useApi.requestV2('patch', `v1/surveys/${payload.id}`, payload).then((response) => {
      const data = response.data
      formOptions.data = data

      // Add address_id to formOptions.data if provided
      if (address_id) {
        formOptions.data.address_id = address_id
      }

      addForm.create(formOptions)
      showAdd.value = true
      loadHandler.setLoadingState('get_details', false)
    })
  }

  // exposed
  return {
    fields: readonly(fields),
    records: readonly(records),
    getRecords,
    loadHandler,
    deleteRecord,
    showAdd,
    showEdit,
    addForm,
    addRecord,
    editRecord,
    openEdit,
    editForm,
    closeEdit,
    openAdd,
    closeAdd,
    exportRecord,
    duplicateRecord,
  }
}
