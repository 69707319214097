<template>
  <div class="h-full min-h-0" v-if="useContentGuard.canSee('organisation:proces')">
    <div class="h-full">
      <div class="flex h-full flex-col">
        <!-- Header -->
        <div>
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <span class="mr-6">
                <backButton />
              </span>
              <basePageTitle
                v-if="!useAddressDetails.loadHandler.states.get_address_details"
                :title="useAddressDetails.address.value.address"
              >
                <p class="text-slate-500 text-xs">{{ useAddressDetails.address.value.zip }}</p>
                <p class="text-slate-500 text-xs">{{ useAddressDetails.address.value.place }}</p>
              </basePageTitle>
              <base-loader
                class="text-xl text-slate-500 my-auto"
                :loading="useAddressDetails.loadHandler.states.get_address_details"
              />
            </div>
          </div>

          <!-- Address Details and Map -->

          <!-- Surveys and Tabs -->
          <div>
            <div v-if="!useAddressDetails.loadHandler.states.get_address_details">
              <baseTabs :handler="tabs">
                <template v-slot:panel-info>
                  <div class="flex flex-col gap-4">
                    <div class="flex flex-row gap-4">
                      <baseCard
                        v-if="!useAddressDetails.loadHandler.states.get_address_details"
                        class="overflow-y-auto basis-1/2"
                      >
                        <base-key-value
                          :data="useAddressDetails.address.value"
                          :fields="useAddressDetails.addressDetailFields.value"
                        />
                      </baseCard>
                      <div class="basis-1/2">
                        <div v-show="!useAddressDetails.loadHandler.states.get_address_details" class="h-full">
                          <div
                            ref="mapRef"
                            class="w-full h-full z-10 rounded border shadow-md bg-white"
                            id="detail_map"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row gap-4">
                      <baseCard v-if="contactDetails" class="basis-1/2">
                        <div class="flex flex-row items-start">
                          <base-key-value :data="contactDetails" :fields="contactDetailFields" />
                          <div @click="onOpenEditContactDetails" class="mb-auto cursor-pointer">
                            <featherIcon icon="EditIcon" class="w-4 h-4 text-slate-500" />
                          </div>
                        </div>
                      </baseCard>
                      <div class="basis-1/2 flex">
                        <div class="mr-auto">
                          <base-card
                            v-if="Object.keys(useAddressDetails.label.value).length > 0"
                            :loading="useAddressDetails.loadHandler.somethingLoading.value"
                            :title="`Energielabel: ${useAddressDetails.label.value.energieklasse}`"
                            titleIcon="TagIcon"
                            :class="labelStyle(useAddressDetails.label.value.energieklasse)"
                            class="min-h-42"
                            @click="tagClick()"
                          >
                            <div class="text-sm">{{ useAddressDetails.label.value.gebouwtype }}</div>
                            <div class="text-sm">{{ useAddressDetails.label.value.registratiedatum }}</div>
                          </base-card>
                          <base-card
                            v-else-if="!useAddressDetails.loadHandler.states.get_address_details"
                            :loading="useAddressDetails.loadHandler.somethingLoading.value"
                            title="Energielabel: onbekend"
                            titleIcon="TagIcon"
                            class="hover:bg-gray-50 cursor-pointer text-gray-500 min-h-24"
                            @click="tagClick()"
                          >
                          </base-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:panel-surveys>
                  <div class="w-full">
                    <baseTable
                      :fields="useSurvey.fields.value"
                      :records="useSurvey.records.value"
                      :records-per-page="25"
                      :add-button="true"
                      :edit-button="true"
                      :delete-button="true"
                      :export-record-button="true"
                      :duplicate-button="true"
                      :clickAble="true"
                      :clickCallback="tableAction"
                      :loading="useSurvey.loadHandler.somethingLoading.value"
                      @add="onOpenAdd"
                      @edit="onOpenEdit"
                      @delete="onDelete"
                      @duplicate="(id) => useSurvey.duplicateRecord(id, route.params.objectId)"
                      @export_record="useSurvey.exportRecord"
                    />
                  </div>
                </template>
                <template v-slot:panel-tasks v-if="route.params.projectId">
                  <div class="w-full" v-if="route.params.objectId">
                    <tasksTable />
                  </div>
                </template>
                <template v-slot:panel-attachments_comments>
                  <div class="flex flex-row gap-4">
                    <div class="basis-1/2">
                      <baseComments :commentable_id="route.params.objectId" commentable_type="address" :title="false" />
                    </div>
                    <div class="basis-1/2">
                      <baseAttachments
                        :attachable_id="route.params.objectId"
                        attachable_type="address"
                        :title="false"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:panel-appointments>
                  <div class="">
                    <baseAppointments
                      :address="
                        useAddressDetails.address.value.address +
                        ', ' +
                        useAddressDetails.address.value.zip +
                        ' ' +
                        useAddressDetails.address.value.place
                      "
                    />
                  </div>
                </template>
              </baseTabs>
            </div>
          </div>

          <!-- Modals -->
          <baseModal
            v-if="useSurvey.showAdd.value"
            @close="useSurvey.closeAdd()"
            :title="$translate('Add')"
            @enter="onEnter()"
          >
            <baseForm :handler="useSurvey.addForm" :loading="useSurvey.loadHandler.states['add_record']" />
            <template v-slot:buttons>
              <baseButton @action="useSurvey.addRecord()" :loading="useSurvey.loadHandler.states['add_record']">
                {{ $translate('Add') }}
              </baseButton>
              <baseButton
                class="mr-2"
                @action="useImport.openFilePicker('woningtool', syncWoningtool)"
                :loading="useSurvey.loadHandler.states['import_record']"
              >
                <div class="flex felx-row">
                  <featherIcon class="w-5 h-5 mr-2" icon="UploadIcon" />
                  <span>Woningtool</span>
                </div>
              </baseButton>
              <baseButton
                v-if="useContentGuard.canSee('monitor:import')"
                class="mr-2"
                @action="useImport.openImportXMLModal()"
                :loading="useImport.loadHandler.states['import_record']"
              >
                <div class="flex felx-row">
                  <featherIcon class="w-5 h-5 mr-2" icon="UploadIcon" />
                  <span>Monitor</span>
                </div>
              </baseButton>
            </template>
          </baseModal>

          <baseModal v-if="useSurvey.showEdit.value" @close="useSurvey.closeEdit()" :title="$translate('Edit')">
            <baseForm :handler="useSurvey.editForm" :loading="useSurvey.loadHandler.states['edit_record']" />
            <template v-slot:buttons>
              <baseButton @action="useSurvey.editRecord()" :loading="useSurvey.loadHandler.states['edit_record']">
                {{ $translate('Edit') }}
              </baseButton>
            </template>
          </baseModal>

          <baseModal
            v-if="showEditContactDetailsForm"
            @close="showEditContactDetailsForm = false"
            :title="$translate('Edit')"
          >
            <baseForm :handler="editContactDetailsForm" />
            <template v-slot:buttons>
              <baseButton @action="editContactDetails()">
                {{ $translate('Edit') }}
              </baseButton>
            </template>
          </baseModal>

          <baseModal
            v-if="useImport.showImportXMLModal.value"
            @close="useImport.closeImportXMLModal()"
            title="Import monitorbestanden"
          >
            <baseForm :handler="useImport.importXMLForm" :loading="useImport.loadHandler.states['import_record']" />
            <template v-slot:buttons>
              <baseButton @action="onImport" :loading="useImport.loadHandler.states['import_record']">
                {{ $translate('Import') }}
              </baseButton>
            </template>
          </baseModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import addressDetailHandler from '@/use/addressDetailHandler'
import nodeMapHandler from '@/use/nodeMapHandler'
import surveyHandler from '@/use/surveyHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import commentHandler from '@/use/commentHandler'
import attachmentHandler from '@/use/attachmentHandler'
import tabHandler from '@/use/tabHandler'
import apiHandler from '@/use/apiHandler'
import importHandler from '@/use/importHandler'
import formHandler from '@/use/formHandler'
import tasksTable from '@/components/extended/tasksTable.vue'

const router = useRouter()
const route = useRoute()
const useAddressDetails = addressDetailHandler()
const useNodeMap = nodeMapHandler()
const useSurvey = surveyHandler()
const useContentGuard = contentGuardHandler()
const useComments = commentHandler()
const useAttachments = attachmentHandler()
const tabs = tabHandler()
const useApi = apiHandler()
const useImport = importHandler()
const showEditContactDetailsForm = ref(false)
const editContactDetailsForm = formHandler()
const projects = ref([])
const mapRef = ref(null)
const contactDetails = ref({
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
})
const tabOptions = computed(() => {
  return {
    tabs: [
      { id: 'info', label: 'Informatie', active: true },
      { id: 'surveys', label: 'Opnames', active: true },
      { id: 'tasks', label: 'Taken', active: route.params.projectId ? true : false },
      { id: 'attachments_comments', label: 'Bijlagen & Opmerkingen', active: true },
      { id: 'appointments', label: 'Afspraken', active: true },
    ],
  }
})

const contactDetailFields = [
  { key: 'first_name', label: 'Voornaam', type: 'string' },
  { key: 'last_name', label: 'Achternaam', type: 'string' },
  { key: 'email', label: 'Email', type: 'email' },
  { key: 'phone_number', label: 'Telefoon', type: 'string' },
]

editContactDetailsForm.create({ type: 'edit', fields: contactDetailFields, data: contactDetails.value })

onMounted(async () => {
  const payload = { id: route.params.objectId }
  console.log(payload)
  console.log(route.params)
  await useAddressDetails.getDetails(payload)
  await loadRelatedData()
  tabs.create(tabOptions.value)

  const requestFields = ['id', 'name']
  const fieldsParam = '?fields=' + requestFields.join(',')
  await useApi.requestV2('get', `v1/projects/${fieldsParam}`).then((result) => {
    projects.value = result.data
  })
  await useApi.requestV2('get', 'v1/addresses/' + route.params.objectId + '/persons').then((result) => {
    if (result.data.length !== 0) {
      contactDetails.value = result.data[0]
    }
  })
})

watch(
  () => mapRef.value,
  () => {
    if (mapRef.value) {
      renderMap()
    }
  }
)

function renderMap() {
  const geometry = useAddressDetails.address_geometry.value
  const settings = {
    lat: geometry.lat,
    lng: geometry.lng,
    zoom: 19,
    markerType: 'pinMarker',
    nodeId: null,
    geometry: geometry,
    energieKlasse: useAddressDetails.label.value?.[0]?.energieklasse ?? 'default',
  }
  useNodeMap.renderMap('detail_map', 'map_detail', false, settings)
}

async function loadRelatedData() {
  const id = useAddressDetails.address.value.id
  useSurvey.getRecords(id)
  useComments.list({ commentable_id: id, commentable_type: 'address' })
  useAttachments.list({ attachable_id: id, attachable_type: 'address' })
}

function tagClick() {
  // Implement tag click functionality
}

function labelStyle(energieklasse) {
  const styles = {
    'A++++': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
    'A+++': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
    'A++': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
    'A+': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
    A: '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
    B: '!bg-[#79b752] basis-1/2 cursor-pointer hover:!bg-[#79b752]/75 text-white',
    C: '!bg-[#c3d545] basis-1/2 cursor-pointer hover:!bg-[#c3d545]/75 text-white',
    D: '!bg-[#fff12c] basis-1/2 cursor-pointer hover:!bg-[#fff12c]/75 text-white',
    E: '!bg-[#edb731] basis-1/2 cursor-pointer hover:!bg-[#edb731]/75 text-white',
    F: '!bg-[#d66f2c] basis-1/2 cursor-pointer hover:!bg-[#d66f2c]/75 text-white',
    G: '!bg-[#cc232a] basis-1/2 cursor-pointer hover:!bg-[#cc232a]/75 text-white',
  }
  return styles[energieklasse] || ''
}

function tableAction(payload) {
  router.push({ name: 'survey', params: { objectId: payload['id'] } })
}

function onOpenAdd() {
  const address_data = useAddressDetails.address.value
  const payload = {
    address: address_data.address,
    address_id: address_data.id,
    zip: address_data.zip,
    place: address_data.place,
  }
  if (route.params.projectId) {
    payload.project = route.params.projectId
  }
  useSurvey.openAdd(payload, address_data.id, projects)
}

function onOpenEdit(data) {
  const address_data = useAddressDetails.address.value
  useSurvey.openEdit(data, address_data.id, projects)
}

function onDelete(id) {
  const address_id = useAddressDetails.address.value.id
  useSurvey.deleteRecord(id, null, address_id)
}

function onImport() {
  useImport.confirmImportXML().then((result) => {
    Object.assign(useSurvey.addForm.data.value, result)
    useSurvey.addForm.update_errors()
  })
}

function syncWoningtool(file) {
  if (file instanceof File) {
    const reader = new FileReader()
    reader.onload = (event) => {
      try {
        const jsonData = JSON.parse(event.target.result)
        Object.assign(useSurvey.addForm.data.value, jsonData)
      } catch (error) {
        console.error('Error parsing Woningtool file:', error)
      }
    }
    reader.onerror = (event) => console.error('File reading error:', event.target.error)
    reader.readAsText(file)
  } else {
    console.error('Invalid file object:', file)
  }
}

function onOpenEditContactDetails() {
  editContactDetailsForm.data.value = { ...contactDetails.value }
  showEditContactDetailsForm.value = true
}

function editContactDetails() {
  let payload = {
    first_name: editContactDetailsForm.data.value.first_name,
    last_name: editContactDetailsForm.data.value.last_name,
    email: editContactDetailsForm.data.value.email,
    phone_number: editContactDetailsForm.data.value.phone_number,
  }
  if (contactDetails.value.id) {
    useApi
      .requestV2('patch', `v1/addresses/${route.params.objectId}/contact_persons/${contactDetails.value.id}`, payload)
      .then(() => {
        contactDetails.value = payload
        showEditContactDetailsForm.value = false
      })
  } else {
    useApi.requestV2('post', `v1/addresses/${route.params.objectId}/contact_persons`, payload).then(() => {
      contactDetails.value = payload
      showEditContactDetailsForm.value = false
    })
  }
}
</script>
